import React, { useEffect, useState } from "react";
import Toast from "../../Utils/Toast";

const SocietyBulkUploadEdit = ({ isOpen, closeModal, editData, updateNewData }) => {
  
  const [formData, setFormData] = useState({
    "Active status": "",
    "Contact Person 1": "",
    "Contact Person 2": "",
    "Contact Person 3": "",
    "District": "",
    "Latitude & Langitude": "",
    "Mobile Number 1": "",
    "Mobile Number 2": "",
    "Mobile Number 3": "",
    "No of Active Members": "",
    "Quality": "",
    "Remarks": "",
    "S.no": "",
    "Society Address": "",
    "Society ID": "",
    "Society Name": "",
    "Taluk": "",
    "Village": ""
  });

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitData = (e) => {
    e.preventDefault(); 
    Toast({ message: "Updated Successfully", type: "success" }); 
    updateNewData(formData);
    closeModal();
  };

  const handleCancel =(e) =>{
    e.preventDefault();
    closeModal();
    setFormData({
        "Project ID": "",
        "Project sourced by": "",
        "Project Status":"",
        "Date & Time": "",
        "Source of Project":"",
        "Owned by":" ",
        "Company Name": "",
        "Contact Details":"",
        "Owner Ranking":"",
        "Project Identified for":"",
        "Project Type" :"",
        "Approval Type" : "",
        "Approval No" : "",
        "Project Launch Year":"",
        "Project Condition" :"",
        "Project Name":"",
        "Village" : "",
        "Project Location":"",
        "Taluk" : "",
        "District" : "",
        "Enter Village Name Manually":"",
        "Location Address":" ",
        "Village/Taluk/District": "",
        "Total Acres": "",
        "Total Plots":"",
        "Total Saleable Plots": "",
        "Cost":"",
        "Total Blocks": "",
        "A Block": "",
        "B Block":"",
        "C Block":"",
        "D Block":"",
        "E Block":"",
        "Corner Plot":"",
        "Guideline Value":"",
        "Documentation & Patta Charges":"",
        "Computer Fees":"",
        "Booking Advance":"",
        "Bank Details":"",
        "Survey No" :"",
        "Capture Photos":"",
        "Google Drive Photos & Videos": "",
        "Geo Link": "",
        "Location Address":"",
        "Remarks":"",
        "User":"",
        "Note":"",
        "UID AB":"",
        "Unique Code":"",
        "Project Location":"",
        "Distance from AH (km)":"",
        "Distance from NH (km)":"",
        "Distance from SH (km)":"",
        "Distance from MDR (km)":"",
        "Distance from ODR (km)":"",
        "Distance from VR (km)":"",
        "Have you checked the 4 Direction Developments?":"",
        "List down the Nearest Towns with Km" :"",
        "List down the Nearest Bus, Railways & Airport with Km":"",
        "List down the Nearest Hospitals with KM":"",
        "List down the Nearest Bank with KM":"",
        "List down the Nearest SIPCOT / SEZ with KM":"",
        "List down the Nearest Tourism Attraction with KM":"",
        "Project Data Status":""       
    })
  }

  return (
    <div
      className={`modal modal-overlay ${isOpen ? "d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <h4 className="page_subheading m-3">Edit Society Data</h4>
            <button
              type="button"
              className="close closebutton"
              onClick={handleCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card"> 
            <div className="card-body p-3">
        
            <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Society ID</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Society ID" 
                                readOnly
                                value={formData["Society ID"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                  Society Name
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Society Name"
                                value={formData["Society Name"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Society Address</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Society Address"
                                value={formData["Society Address"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                   
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Latitude & Langitude
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Latitude & Langitude" 
                                value={formData["Latitude & Langitude"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 District
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="District" 
                                value={formData["District"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Taluk
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Taluk" 
                                value={formData["Taluk"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Village
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Village" 
                                value={formData["Village"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Contact Person 1" 
                                value={formData["Contact Person 1"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Mobile Number 1" 
                                value={formData["Mobile Number 1"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Contact Person 2" 
                                value={formData["Contact Person 2"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Mobile Number 2" 
                                value={formData["Mobile Number 2"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Contact Person 3" 
                                value={formData["Contact Person 3"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="Mobile Number 3" 
                                value={formData["Mobile Number 3"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                No of Active Members
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="No of Active Members" 
                                value={formData["No of Active Members"]} 
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Quality</label>
                            </div>
                            <div className="col-7">
                            <select className="form-select"   name="quality" value={formData.quality} onChange={handleChange}> 
                              <option value="">Select a quality...</option>
                               
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Active status
                              </label>
                            </div>
                            <div className="col-7">
                            <select className="form-select"  name="Active status"  value={formData["Active status"]} onChange={handleChange}> 
                                <option value="">Select a status</option>
                                <option value="completed">Completed</option>
                                <option value="progress">In-progress</option>
                                <option value="notstarted">Not Started</option> 
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Remarks</label>
                            </div>
                            <div className="col-7">
                              <textarea 
                                type="text"
                                className="form-control"
                                name="Remarks" 
                                value={formData["Remarks"]}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
               
  

                        <div className="text-end mt-4 mb-4">
                          <button className="btn1 me-1" type="button">Clear</button>
                          <button type="submit" className="btn1" onClick={submitData}>
                            Add
                          </button>
                        </div>

                    </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyBulkUploadEdit;
