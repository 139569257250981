import React, { useEffect, useRef, useState } from "react";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DistrictCityEdit from "./DistrictCityEdit";
import ExportButton from "../../../Utils/ExportButton";
import { SearchData } from "../../../Utils/Search";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tab, Tabs } from "react-bootstrap";
import customStyle from "../../../Utils/tableStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  addDistrict,
  deleteDistrict,
  fetchDistrict,
} from "../../../Redux/Actions/MasterPage/DistrictAction";
import Select from "react-select";
import { DistrictvalidateFormData, DistrictvalidateFormDatas } from "./validation";
import Toast from "../../../Utils/Toast";
import { DeleteById } from "../../../Utils/DeleteById";
import ExcelFileUpload from "../../../Utils/ExcelFileUpload";
import { fetchState } from "../../../Redux/Actions/MasterPage/StateAction";

function District() {
  // get a district datas from store
  const districtData = useSelector((state) => state.District.districtData);
  const stateData = useSelector((state) => state.State.StateNameData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDistrict());
    dispatch(fetchState());
  }, [dispatch]);
 

  // State dropdown
  const options = stateData.map((data) => ({
    value: data.id,
    label: data.state_name,
  }));

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChangeSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFormData({
      ...formData,
      state_type: selectedOption.value,
    });

    setBulkFormData({
      ...bulkFormData,
      state_type: selectedOption.value,
    });
  };

  // add new data
  const [formData, setFormData] = useState({
    state_type: "",
    district: "",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const jsonArray = [formData];
    const validationResult = DistrictvalidateFormData(formData);
    if (validationResult.isValid) {
      Toast({ message: "Added successfully", type: "success" });
      setErrors("");
      setFormData({ state_type: "", district: "", status: "" });
      setSelectedOption(null);
      dispatch(addDistrict(jsonArray));
    } else {
      setErrors(validationResult.errors);
    }
  };

  // edit  district
  const [editData, setEditData] = useState();
  const handleEdit = (row) => {
    setEditData(row);
  };

  // delete district
  const handleDelete = (row) => {
    DeleteById(row.id, deleteDistrict, dispatch);
  };

  // edit modal open
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // bulk upload data get
  const fileUploadRef = useRef(null);
  const [excelData, setExcelData] = useState([]);
  const [bulkFormData, setBulkFormData] = useState({
    state_type: "",
    district: "",
    status: "Enable",
  });

  // convert json format
  const bulkData = excelData.map((data) => ({
    state_type: bulkFormData.state_type,
    district: data.District,
    status: bulkFormData.status,
  }));

  const [errorMessageBulk, setErrorMessageBulk] = useState(""); //validation messages

  const bulkSubmit = (e) => {
    e.preventDefault(); 

    const validationResult = DistrictvalidateFormDatas(bulkData[0]);

    if (validationResult.isValid) {
      fileUploadRef.current.value = "";
      Toast({ message: "Added successfully", type: "success" });
      setExcelData([]);
      setSelectedOption(null);
      setErrorMessageBulk("");
      dispatch(addDistrict(bulkData));
    } else {
      setErrorMessageBulk(validationResult.errorMessage);
    }
  };

  // table column
  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdat,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row.district,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];
  const formatColumn =[
    {
      name: "District",
      selector: (row) => row.sno, 
    },
  ]





  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = ["sno","createdat", "state_name", "district", "status"];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(districtData, filterText, searchColumns);
  /////////////////////////////////////
  return (
    <>
      <Topbar />
      <DistrictCityEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
        dropdown={options}
      />
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <Tabs
                  defaultActiveKey="tab1"
                  id="fill-tab-example"
                  className=" "
                  fill
                >
                  <Tab eventKey="tab1" title="Add Single District">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label className="form-label" htmlFor="inputState">
                              State
                            </label>
                            <Select
                              options={options}
                              value={selectedOption}
                              onChange={handleChangeSelect}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "#e7e7e7"
                                    : "#e7e7e7",
                                  fontSize: "13px",
                                }),
                                option: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontSize: "12px",
                                  color: "black",
                                }),
                              }}
                            />
                             {errors.state_type && <div className="validation_msg">{errors.state_type}</div>}
                          </div>

                          <div className="col-md-12 mb-3 ">
                            <label htmlFor="lastName" className="form-label">
                              District
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="district"
                              value={formData.district}
                              onChange={handleChange}
                            />
                               {errors.district && <div className="validation_msg">{errors.district}</div>}
                          </div>
                        </div>

                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="inputState">
                            Status
                          </label>
                          <select
                            id="inputState"
                            className="form-select"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                          >
                            <option disable value="">
                              --Select Status--
                            </option>
                            <option value="Enable">Enable</option>
                            <option value="Disable">Disable</option>
                          </select>
                          {errors.status && <div className="validation_msg">{errors.status}</div>}
                        </div>

                        

                        <div className="text-end py-3 px-3">
                          <button
                            type="button"
                            className="btn1 me-1"
                            onClick={() => {
                              setFormData({
                                state_type: "",
                                district: "",
                                status: "",
                              });
                              setErrors("");
                              setSelectedOption(null);
                            }}
                          >
                            Clear
                          </button>
                          <button className="btn1" onClick={onSubmit}>
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </Tab>
                  <Tab eventKey="longer-tab" title="Add Multiple District">
                    <div className="card-body">
                      <form>
                        <div className="col-md-12 mb-3 ">
                          <label className="form-label" htmlFor="inputState">
                            State
                          </label>
                          <Select
                            options={options}
                            value={selectedOption}
                            onChange={handleChangeSelect}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                fontSize: "13px",
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: "12px",
                                color: "black",
                              }),
                            }}
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label htmlFor="lastName" className="form-label">
                              Upload the excel file
                            </label>
                            <ExcelFileUpload
                              setExcelData={setExcelData}
                              fileInputRef={fileUploadRef}
                            />
                          </div>
                        </div>

                        {errorMessageBulk && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessageBulk}
                          </div>
                        )}

                        <div className="col-md-12 d-flex py-3 px-3">
                          <ExportButton
                            columns={formatColumn}
                            data={[]}
                            filename={"District.csv"}
                            type={"Format"}
                          />

                          <div style={{ marginLeft: "auto" }}>
                            <button
                              type="button"
                              className="btn1 me-1"
                              onClick={() => {
                                setFormData({
                                  state_type: "",
                                  district: "",
                                  status: "",
                                });
                                setErrorMessageBulk("");
                                setSelectedOption(null);
                              }}
                            >
                              Clear
                            </button>
                            <button className="btn1" onClick={bulkSubmit}>
                              Add
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={filterdata}
                        filename={"district.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      // selectableRows
                      persistTableHead={true}
                      fixedHeader
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactTooltip
        id="edit"
        place="bottom"
        content="Edit"
        style={{ fontSize: "10px" }}
      />
      <ReactTooltip
        id="delete"
        place="bottom"
        content="Delete"
        style={{ fontSize: "10px" }}
      />
      <Footerbar />
    </>
  );
}

export default District;
