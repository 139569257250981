import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";  
import "react-toastify/dist/ReactToastify.css"; 
import Topbar from "../../Components/topbar/topbar";
import Footerbar from "../../Components/footer/footer";
import Select from "react-select";
import "./creation.css"
import StateDropDown from "../../Utils/SelectDropDown/StateDropDown";
import DistrictDropDown from "../../Utils/SelectDropDown/DistrictDropDown";
import TalukDropDown from "../../Utils/SelectDropDown/TalukDropDown";
import VillageDropDown from "../../Utils/SelectDropDown/VillageDropDown";
import Toast from "../../Utils/Toast";
import { useDispatch, useSelector } from "react-redux";
import { addBulkUploadSourceBook, fetchDefaultID} from "../../Redux/Actions/SourceBook/sourceBookAction";


function Creation() {
 
  const dispatch = useDispatch();

  const DefaultID = useSelector((state) => state.SourceBook.SourceDefaultID); 

   useEffect(() => {
      dispatch(fetchDefaultID());
    }, [dispatch]);

 

  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const options = [
    { value: "1", label: "Ambattur 20km" },
    { value: "2", label: "Avadi 12km" },
    { value: "3", label: "Egmore 4km" },
  ];

 
  const [formData, setFormData] = useState({
    // project_id:DefaultID,
    source: "",
    project_status: "",
    source_date: "",
    source_project: "",
    ownedby: "",
    company: "",
    contact: "",
    owner_rank: "",
    project_identi: "",
    project_type: "",
    approval_type: "",
    approval_on: "",
    launch_year: "",
    project_condition: "",
    project_name: "",
    state: "",
    village: "",
    project_loc: "",
    taluk: "",
    distric: "",
    pincode:"",
    village_manually: "",
    location: "",
    village_taluk: "",
    total_acres: "",
    total_plots: "",
    total_sale: "",
    total_sqft:"",
    total_salesqft:"",
    available_plot:"",
    total_blocks: "",
    marketing_price:"",
    selling_price:"",
    ablock: "",
    bblock: "",
    cblock: "",
    dblock: "",
    eblock: "",
    corner_plot: "",
    guideline: "",
    patta_charges: "",
    computer_fees: "",
    booking_advance: "",
    bank_details: "",
    capture_photos: "",
    google_drive: "",
    geo_location: "",
    location_address: "",
    geo_link: "",
    reamrk: "",
    user: "",
    note: "",
    uid_ab: "",
    unique_code: "",
    projected_located: "",
    distance_ah: "",
    distance_nh: "",
    distance_sh: "",
    distance_mdr: "",
    distance_odr: "",
    distance_vr: "",
    direction: "",
    nearest_town: "",
    nearest_stops: "", 
    nearest_hospital: "",
    nearest_bank: "",
    nearest_sipcot: "",
    nearest_tourism: "",
    project_data: "",
    contact1: "",
    contact2: "",
    contact3: "",
    mobile1: "",
    mobile2: "",
    mobile3: "",
    preference:"adiyogi"
  });

  useEffect(() => {
    if (DefaultID) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            project_id: `SRB${DefaultID}`,
        }));
    }
}, [DefaultID]);

  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const submitData = async(e) => {
    e.preventDefault();   
    if ("sno" in formData) {
      delete formData["sno"];  
    } 
    await dispatch(addBulkUploadSourceBook([formData]))  
  };


  // dropdown 
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);

  const handleStateSelect = (state) => {
    setSelectedState(state);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
  };

  const handleDistrictSelect = (district) => {
    setSelectedDistrict(district);
    setSelectedTaluk(null);
  };

  const handleTalukSelect = (taluk) => {
    setSelectedTaluk(taluk);
    setSelectedVillage(null);
  };

  const handleVillageSelect = (village) => {
    setSelectedVillage(village);
  };
  return (
    <>
      <Topbar /> 
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-heaer mb-3">
                  <div className="d-flex bottom1 justify-content-center">
                    <div>
                      <nav className="nav">
                        <a
                          className={`link1 ${
                            step === 1 ? "active1" : ""
                          }`}
                          href="#"
                          onClick={() => setStep(1)}
                        >
                          Group 1
                        </a>
                        <a
                          className={`link1 ${
                            step === 2 ? "active1" : ""
                          }`}
                          href="#"
                          onClick={() => setStep(2)}
                        >
                          Group 2
                        </a>
                        <a
                          className={`link1 ${
                            step === 3 ? "active1" : ""
                          }`}
                          href="#"
                          onClick={() => setStep(3)}
                        >
                          Group 3
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  {step === 1 && (
       


                    <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Project ID</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_id" 
                                readOnly
                                value={`SRB${DefaultID}`}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Project sourced by
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="source"
                                value={formData.source}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
             
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Date & Time</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="date"
                                className="form-control"
                                name="source_date"
                                value={formData.source_date}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Source of Project
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="source_project" 
                                value={formData.source_project}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Owned by</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="ownedby" 
                                value={formData.ownedby}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Company Name</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="company" 
                                value={formData.company}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Preference</label>
                            </div>
                            <div className="col-7">
                            <select className="form-select" name="preference" value={formData.preference} onChange={handleChange}> 
                                <option value="adiyogi">Adiyogi</option>
                                <option value="goolok">Goolok</option> 
                            </select>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Details
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact" 
                                value={formData.contact}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact1" 
                                value={formData.contact1}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile1" 
                                value={formData.mobile1}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact2" 
                                value={formData.contact2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile2" 
                                value={formData.mobile2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact3" 
                                value={formData.contact3}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile3" 
                                value={formData.mobile3}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  





                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Owner Ranking</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="owner_rank" 
                                value={formData.owner_rank}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Project Identified for
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_identi" 
                                value={formData.project_identi}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Project Type</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_type" 
                                value={formData.project_type}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Approval No</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="approval_on" 
                                value={formData.approval_on}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Project Condition
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_condition" 
                                value={formData.project_condition}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Project Name</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_name" 
                                value={formData.project_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">State</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="state" 
                                value={formData.state}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Village</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="village" 
                                value={formData.village}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Taluk</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="taluk" 
                                value={formData.taluk}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">District</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="distric" 
                                value={formData.distric}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Pincode</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="pincode" 
                                value={formData.pincode}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Enter Village Name Manually
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="village_manually" 
                                value={formData.village_manually}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
  
  
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Project Location
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_loc" 
                                value={formData.project_loc}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
            
                   
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                              Survey No
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="survey_no" 
                                value={formData.survey_no}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
  
  
                   
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Capture Photos</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="capture_photos" 
                                value={formData.capture_photos}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
  
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Google Drive Photos & Videos
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="google_drive" 
                                value={formData.google_drive}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                 
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Location Address
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="location_address" 
                                value={formData.location_address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Remarks</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="reamrk" 
                                value={formData.reamrk}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">User</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="user" 
                                value={formData.user}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end py-3 px-3">
                      {/* <button className="btn1 me-1">Clear</button> */}
                      <button className="btn1" onClick={nextStep}>
                        Next
                      </button>
                    </div>
                  </div>
                  )}
                  {step === 2 && (
                   <div>
                   <div className="row">
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">
                               Project Launch Year
                             </label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="launch_year" 
                               value={formData.launch_year}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Total Acres</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_acres" 
                               value={formData.total_acres}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
 
                   <div className="row">
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Total Plots</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_plots" 
                               value={formData.total_plots}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">
                               Total Saleable Plots
                             </label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_sale" 
                               value={formData.total_sale}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
 
                   <div className="row">
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Total Sqft</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_sqft"  
                               value={formData.total_sqft}
                               onChange={handleChange} 
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">
                               Total Saleable Sqft
                             </label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_salesqft" 
                               value={formData.total_salesqft}
                               onChange={handleChange}
                      
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
 
                   <div className="row">

                   <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                            Available Plots
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="available_plot" 
                              value={formData.available_plot}
                              onChange={handleChange}
                     
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">
                               Marketing Price
                             </label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="marketing_price"
                               value={formData.marketing_price} 
                               onChange={handleChange} 
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Selling Price</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="selling_price"
                               value={formData.selling_price}
                               onChange={handleChange} 
                              
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Total Blocks</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="total_blocks" 
                               value={formData.total_blocks}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">A Block</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="ablock" 
                               value={formData.ablock}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">B Block</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="bblock" 
                               value={formData.bblock}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">C Block</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="cblock" 
                               value={formData.cblock}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">D Block</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="dblock" 
                               value={formData.dblock}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">E Block</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="eblock" 
                               value={formData.eblock}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Corner Plot</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="corner_plot" 
                               value={formData.corner_plot}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Note</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="note" 
                               value={formData.note}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">UID AB</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="text"
                               className="form-control"
                               name="uid_ab" 
                               value={formData.uid_ab}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
 
                     <div className="col-md-12 col-lg-6">
                       <div className="form-group mt-3">
                         <div className="row">
                           <div className="col-4">
                             <label className="form-label">Unique Code</label>
                           </div>
                           <div className="col-7">
                             <input
                               type="number"
                               className="form-control"
                               name="unique_code" 
                               value={formData.unique_code}
                               onChange={handleChange}
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
 
                   <div className="text-end py-3 px-3">
                     <button className="btn1 me-1" onClick={prevStep}>
                       Previous
                     </button>
                     <button className="btn1" onClick={nextStep}>
                       next
                     </button>
                   </div>
                 </div>
                  )}

                  {step === 3 && (
                       <div>
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Guideline Value
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="guideline" 
                                   value={formData.guideline}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Documentation & Patta Charges
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="patta_charges" 
                                   value={formData.patta_charges}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Booking Advance
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="booking_advance" 
                                   value={formData.booking_advance}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">Computer Fees</label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="computer_fees" 
                                   value={formData.computer_fees}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">Bank Details</label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="bank_details" 
                                   value={formData.bank_details}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Project is located on
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="project_loc" 
                                   value={formData.project_loc}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from AH (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_ah" 
                                   value={formData.distance_ah}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from NH (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_nh" 
                                   value={formData.distance_nh}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from SH (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_sh" 
                                   value={formData.distance_sh}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from MDR (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_mdr" 
                                   value={formData.distance_mdr}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from ODR (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_odr" 
                                   value={formData.distance_odr}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Distance from VR (km)
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="number"
                                   className="form-control"
                                   name="distance_vr" 
                                   value={formData.distance_vr}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Have you checked the 4 Direction Developments?
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="direction" 
                                   value={formData.direction}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Towns with Km
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_town" 
                                   value={formData.nearest_town}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Bus, Railways & Airport with
                                   Km
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_stops" 
                                   value={formData.nearest_stops}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Schools & Colleges with KM
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_school" 
                                   value={formData.nearest_school}
                                   onChange={handleChange} 
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Hospitals with KM
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_hospital" 
                                   value={formData.nearest_hospital}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Bank with KM
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_bank" 
                                   value={formData.nearest_bank}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest SIPCOT / SEZ with KM
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_sipcot" 
                                   value={formData.nearest_sipcot}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   List down the Nearest Tourism Attraction with KM
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="nearest_tourism" 
                                   value={formData.nearest_tourism}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
     
                       <div className="row">
                         <div className="col-md-12 col-lg-6">
                           <div className="form-group mt-3">
                             <div className="row">
                               <div className="col-4">
                                 <label className="form-label">
                                   Project Data Status
                                 </label>
                               </div>
                               <div className="col-7">
                                 <input
                                   type="text"
                                   className="form-control"
                                   name="project_data" 
                                   value={formData.project_data}
                                   onChange={handleChange}
                                 />
                               </div>
                             </div>
                           </div>
                         </div>


                         <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Project Status</label>
                            </div>
                            <div className="col-7">
                            <select className="form-select" name="project_status" value={formData.project_status} onChange={handleChange}>
                                <option value="">Select a status</option>
                                <option value="completed">Completed</option>
                                <option value="progress">In-progress</option>
                                <option value="notstarted">Not Started</option>
                            </select>
                            </div>
                          </div>
                        </div>
                      </div>

                       </div>
     

                 


                       <div className="text-end py-3 px-3">
                         <button className="btn1 me-1" onClick={prevStep}>
                           Previous
                         </button>
                         <button className="btn1" onClick={submitData}>
                           Submit
                         </button>
                       </div>
                     </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerbar />
    </>
  );
}

export default Creation;
