import React, {useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import { SearchData } from "../../../src/Utils/Search";
import Topbar from "../../Components/topbar/topbar";
import Footerbar from "../../Components/footer/footer";
import ExportButton from "../../../src/Utils/ExportButton"; 
import * as XLSX from 'xlsx';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateSourceBookReport } from "../../Redux/Actions/SourceBook/sourceBookAction";
import { useDispatch} from "react-redux";
import BulkUploadEdit from "./BulkUploadEdit";
import customStyle from "../../Utils/tableStyle";
import Loader from "../../Components/Loader/Loader";
 

function UpdateBulkUpload() {
  const dispatch = useDispatch();
  const fileUploadRef = useRef(null);


 
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

 
 
  const nameChange = excelData.map((item,index) => ({  
    project_id: item["Project ID"] ? item["Project ID"] : "", 
    source: item["Project sourced by"] ? item["Project sourced by"] : "",
    project_status: item["Project Status"] ? item["Project Status"] : "",
    source_date: item["Date & Time"] ? item["Date & Time"] : "",
    source_project: item["Source of Project"] ? item["Source of Project"] : "",
    ownedby: item["Owned by"] ? item["Owned by"] : "",
    company: item["Company Name"] ? item["Company Name"] : "",
    contact: item["Contact Details"] ? item["Contact Details"] : "",
    owner_rank: item["Owner Ranking"] ? item["Owner Ranking"] : "",
    project_identi: item["Project Identified for"]
      ? item["Project Identified for"]
      : "",
    project_type: item["Project Type"] ? item["Project Type"] : "",
    approval_type: item["Approval Type"] ? item["Approval Type"] : "",
    approval_on: item["Approval No"] ? item["Approval No"] : "",
    project_condition: item["Project Condition"]
    ? item["Project Condition"]
    : "",
    project_name: item["Project Name"] ? item["Project Name"] : "",
    state: item["State"] ? item["State"] : "",
    village: item["Village"] ? item["Village"] : "",
    taluk: item["Taluk"] ? item["Taluk"] : "",
    distric: item["District"] ? item["District"] : "",
    pincode: item["Pincode"] ? item["Pincode"] : "",
    village_manually: item["Enter Village Name Manually"]
    ? item["Enter Village Name Manually"]
    : "",
    project_loc: item["Project Location"] ? item["Project Location"] : "",
    survey_no : item["Survey No"] ? item["Survey No"] : "",
    capture_photos: item["Capture Photos"] ? item["Capture Photos"] : "",
    google_drive: item["Google Drive Photos & Videos"]
    ? item["Google Drive Photos & Videos"]
    : "",
    location_address: item["Location Address"] ? item["Location Address"] : "",
    reamrk: item["Remarks"] ? item["Remarks"] : "",
    user: item["User"] ? item["User"] : "", 
    launch_year: item["Project Launch Year"] ? item["Project Launch Year"] : "",
    total_acres: item["Total Acres"] ? item["Total Acres"] : "",
    total_plots: item["Total Plots"] ? item["Total Plots"] : "",
    total_sale: item["Total Saleable Plots"]? item["Total Saleable Plots"] : "", 
    available_plot: item["Available Plots"]? item["Available Plots"] : "", 
    total_sqft: item["Total Sqft"] ? item["Total Sqft"] :"",
    total_salesqft : item["Total Saleable Sqft"] ? item["Total Saleable Sqft"] :"",
    marketing_price: item["Marketing Price"] ? item["Marketing Price"] :"",
    selling_price:item["Selling Price"] ? item["Selling Price"] :"",
    total_blocks: item["Total Blocks"] ? item["Total Blocks"] : "",
    ablock: item["A Block"] ? item["A Block"] : "",
    bblock: item["B Block"] ? item["B Block"] : "",
    cblock: item["C Block"] ? item["C Block"] : "",
    dblock: item["D Block"] ? item["D Block"] : "",
    eblock: item["E Block"] ? item["E Block"] : "",
    corner_plot: item["Corner Plot"] ? item["Corner Plot"] : "",
    note: item["Note"] ? item["Note"] : "",
    uid_ab: item["UID AB"] ? item["UID AB"] : "",
    unique_code: item["Unique Code"] ? item["Unique Code"] : "", 
    guideline: item["Guideline Value"] ? item["Guideline Value"] : "",
    patta_charges: item["Documentation & Patta Charges"]
      ? item["Documentation & Patta Charges"]
      : "",
    computer_fees: item["Computer Fees"] ? item["Computer Fees"] : "",
    booking_advance: item["Booking Advance"] ? item["Booking Advance"] : "",
    bank_details: item["Bank Details"] ? item["Bank Details"] : "",  
    projected_located: item["Project is located on"] ? item["Project is located on"] : "", 
    distance_ah: item["Distance from AH (km)"]
      ? item["Distance from AH (km)"]
      : "",
    distance_nh: item["Distance from NH (km)"]
      ? item["Distance from NH (km)"]
      : "",
    distance_sh: item["Distance from SH (km)"]
      ? item["Distance from SH (km)"]
      : "",
    distance_mdr: item["Distance from MDR (km)"]
      ? item["Distance from MDR (km)"]
      : "",
    distance_odr: item["Distance from ODR (km)"]
      ? item["Distance from ODR (km)"]
      : "",
    distance_vr: item["Distance from VR (km)"]
      ? item["Distance from VR (km)"]
      : "",
    direction: item["Have you checked the 4 Direction Developments?"]
      ? item["Have you checked the 4 Direction Developments?"]
      : "",
    nearest_town: item["List down the Nearest Towns with Km"]
      ? item["List down the Nearest Towns with Km"]
      : "",
    nearest_stops: item["List down the Nearest Bus, Railways & Airport with Km"]
      ? item["List down the Nearest Bus, Railways & Airport with Km"]
      : "",
    nearest_school : item["List down the Nearest Schools & Colleges with KM"] ? item["List down the Nearest Schools & Colleges with KM"] :"",
    nearest_hospital: item["List down the Nearest Hospitals with KM"]
      ? item["List down the Nearest Hospitals with KM"]
      : "",
    nearest_bank: item["List down the Nearest Bank with KM"]
      ? item["List down the Nearest Bank with KM"]
      : "",
    nearest_sipcot: item["List down the Nearest SIPCOT / SEZ with KM"]
      ? item["List down the Nearest SIPCOT / SEZ with KM"]
      : "",
    nearest_tourism: item["List down the Nearest Tourism Attraction with KM"]
      ? item["List down the Nearest Tourism Attraction with KM"]
      : "",
    project_data: item["Project Data Status"]
      ? item["Project Data Status"]
      : "",
  }));

  const [editData, setEditData] = useState("");
  const handleEdit = (row) => {
     setEditData(row);
  };

  const handleDelete = (row) => {
    const deleteData = excelData.filter(
      (data) => data["Project ID"] !== row["Project ID"]
    ); 
    setExcelData(deleteData);
  };

  // update the data
  const updateNewData = (formData) => {
    const updatedExcelData = excelData.map((item) =>
      item["Project ID"] === formData["Project ID"] ? formData : item
    );
    setExcelData(updatedExcelData);
  };
  
  const handleSubmit =async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
        await dispatch(updateSourceBookReport(nameChange));
    } catch (error) {
        console.error('Error Updating source Book:', error);
    } finally {
         setLoading(false);
    }
    if (fileUploadRef.current) {
        fileUploadRef.current.value = "";
    }
    setExcelData([]) 
  };

  
  const columns = [
    {
      name: "S.no",
      selector: (row) => row["S.no"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Project ID",
      selector: (row) => row["Project ID"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Project sourced by",
      selector: (row) => row["Project sourced by"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Project Status",
      selector: (row) => row["Project Status"],
      wrap: true,
      sortable: true,
      width: "150px", 
    },

    {
      name: "Date & Time",
      selector: (row) => row["Date & Time"],
      wrap: true,
      sortable: true,
      width: "120px", 
    },
    {
      name: "Source of Project",
      selector: (row) => row["Source of Project"],
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Owned by",
      selector: (row) => row["Owned by"],
      wrap: true,
      sortable: true,
      width: "120px",  
    },
    {
      name: "Company Name",
      selector: (row) => row["Company Name"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Contact Details",
      selector: (row) => row["Contact Details"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Owner Ranking",
      selector: (row) => row["Owner Ranking"],
      wrap: true,
      sortable: true,
      width: "150px",  
    },
    {
      name: "Project Identified for",
      selector: (row) => row["Project Identified for"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Project Type",
      selector: (row) => row["Project Type"],
      wrap: true,
      sortable: true,
      width: "150px", 
    },
    {
      name: "Approval Type",
      selector: (row) => row["Approval Type"],
      wrap: true,
      sortable: true,
      width: "150px", 

    },
    {
      name: "Approval No",
      selector: (row) => row["Approval No"],
      wrap: true,
      sortable: true,
      width: "150px", 

    },

    {
      name: "Project Condition",
      selector: (row) => row["Project Condition"],
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Project Name",
      selector: (row) => row["Project Name"],
      wrap: true,
      sortable: true,
      width: "150px", 

    },
 
   
    {
      name: "State",
      selector: (row) => row["State"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Village",
      selector: (row) => row["Village"],
      wrap: true,
      sortable: true,
    },

    {
      name: "Taluk",
      selector: (row) => row["Taluk"],
      wrap: true,
      sortable: true,
    },

    {
      name: "District",
      selector: (row) => row["District"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Pincode",
      selector: (row) => row["Pincode"],
      wrap: true,
      sortable: true,
    },

    {
      name: "Enter Village Name Manually",
      selector: (row) => row["Enter Village Name Manually"],
      wrap: true,
      sortable: true,
      width: "250px",
    },

    {
      name: "Project Location",
      selector: (row) => row["Project Location"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
 
    {
      name: "Survey No",
      selector: (row) => row["Survey No"],
      wrap: true,
      sortable: true,
      width: "200px",
    },

   
    {
      name: "Capture Photos",
      selector: (row) => row["Capture Photos"],
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Google Drive Photos & Videos",
      selector: (row) => row["Google Drive Photos & Videos"],
      wrap: true,
      sortable: true,
      width: "250px",
    },


    {
      name: "Location Address",
      selector: (row) => row["Location Address"],
      wrap: true,
      sortable: true,
      width: "200px",
    },


    {
      name: "Remarks",
      selector: (row) => row["Remarks"],
      wrap: true,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row["User"],
      wrap: true,
      sortable: true,
    },


    {
      name: "Project Launch Year",
      selector: (row) => row["Project Launch Year"],
      wrap: true,
      sortable: true,
      width: "200px",
    },


    {
      name: "Total Acres",
      selector: (row) => row["Total Acres"],
      wrap: true,
      sortable: true,
      width: "120px" 
    },
    {
      name: "Total Plots",
      selector: (row) => row["Total Plots"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Total Saleable Plots",
      selector: (row) => row["Total Saleable Plots"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Available Plots",
      selector: (row) => row["Available Plots"],
      wrap: true,
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Sqft",
      selector: (row) => row["Total Sqft"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Total Saleable Sqft",
      selector: (row) => row["Total Saleable Sqft"],
      wrap: true,
      sortable: true,
      width: "180px",
    },

    {
      name: "Marketing Price",
      selector: (row) => row["Marketing Price"],
      wrap: true,
      sortable: true,
      width: "150px",
    },

    {
      name: "Selling Price",
      selector: (row) => row["Selling Price"],
      wrap: true,
      sortable: true,
      width: "150px",
    },
 
   
    {
      name: "Total Blocks",
      selector: (row) => row["Total Blocks"],
      wrap: true,
      sortable: true,
    },
    {
      name: "A Block",
      selector: (row) => row["A Block"],
      wrap: true,
      sortable: true,
    },
    {
      name: "B Block",
      selector: (row) => row["B Block"],
      wrap: true,
      sortable: true,
    },
    {
      name: "C Block",
      selector: (row) => row["C Block"],
      wrap: true,
      sortable: true,
    },
    {
      name: "D Block",
      selector: (row) => row["D Block"],
      wrap: true,
      sortable: true,
    },
    {
      name: "E Block",
      selector: (row) => row["E Block"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Corner Plot",
      selector: (row) => row["Corner Plot"],
      wrap: true,
      sortable: true,
    },

    {
      name: "Note",
      selector: (row) => row["Note"],
      wrap: true,
      sortable: true, 
    },
    {
      name: "UID AB",
      selector: (row) => row["UID AB"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Unique Code",
      selector: (row) => row["Unique Code"],
      wrap: true,
      sortable: true,
    },


    {
      name: "Guideline Value",
      selector: (row) => row["Guideline Value"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Documentation & Patta Charges",
      selector: (row) => row["Documentation & Patta Charges"],
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "Computer Fees",
      selector: (row) => row["Computer Fees"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Booking Advance",
      selector: (row) => row["Booking Advance"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Bank Details",
      selector: (row) => row["Bank Details"],
      wrap: true,
      sortable: true,
    },
    
    
    {
      name: "Project is located on",
      selector: (row) => row["Project is located on"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from AH (km)",
      selector: (row) => row["Distance from AH (km)"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from NH (km)",
      selector: (row) => row["Distance from NH (km)"],
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Distance from SH (km)",
      selector: (row) => row["Distance from SH (km)"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from MDR (km)",
      selector: (row) => row["Distance from MDR (km)"],
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Distance from ODR (km)",
      selector: (row) => row["Distance from ODR (km)"],
      wrap: true,
      sortable: true,
      width: "220px",
    },

    {
      name: "Distance from VR (km)",
      selector: (row) => row["Distance from VR (km)"],
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Have you checked the 4 Direction Developments?",
      selector: (row) => row["Have you checked the 4 Direction Developments?"],
      wrap: true,
      sortable: true,
      width: "400px",
    },
    {
      name: "List down the Nearest Towns with Km",
      selector: (row) => row["List down the Nearest Towns with Km"],
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "List down the Nearest Bus, Railways & Airport with Km",
      selector: (row) =>
        row["List down the Nearest Bus, Railways & Airport with Km"],
      wrap: true,
      sortable: true,
      width: "420px",
    },
    {
      name: "List down the Nearest Schools & Colleges with KM",
      selector: (row) =>
        row["List down the Nearest Schools & Colleges with KM"],
      wrap: true,
      sortable: true,
      width: "400px",
    },
    {
      name: "List down the Nearest Hospitals with KM",
      selector: (row) => row["List down the Nearest Hospitals with KM"],
      wrap: true,
      sortable: true,
      width: "320px",
    },
    {
      name: "List down the Nearest Bank with KM",
      selector: (row) => row["List down the Nearest Bank with KM"],
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "List down the Nearest SIPCOT / SEZ with KM",
      selector: (row) => row["List down the Nearest SIPCOT / SEZ with KM"],
      wrap: true,
      sortable: true,
      width: "350px",
    },
    {
      name: "List down the Nearest Tourism Attraction with KM",
      selector: (row) =>
        row["List down the Nearest Tourism Attraction with KM"],
      wrap: true,
      sortable: true,
      width: "400px",
    },
    {
      name: "Project Data Status",
      selector: (row) => row["Project Data Status"],
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

 

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [
    "Project ID",
    "Project sourced by",
    "Project Status",
    "Source of Project",
    "Owned by",
    "Company Name",
    "Contact Details",
    "Owner Ranking",
    "Project Identified for",
    "Project Type",
    "Approval Type",
    "Approval No.",
    "Project Condition",
    "Project Name",
    "Village",
    "Project Location",
    "Taluk",
    "State",
    "Pincode",
    "District",
    "Location Address", 
    "Location Address", 
  ];

  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(excelData, filterText, searchColumns);
  /////////////////////////////////////

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        
        // Convert the worksheet to JSON
        let json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

        // Get all headers from the entire sheet
        const allFields = new Set();
        json.forEach(row => {
          Object.keys(row).forEach(field => {
            allFields.add(field);
          });
        });

        // Ensure every row contains all fields
        json = json.map((obj) => {
          const newObj = {};
          allFields.forEach((field) => {
            newObj[field] = obj[field] || '';
          });
          return newObj;
        });
 
        setExcelData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <Topbar />
      <BulkUploadEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
        updateNewData={updateNewData}
      />
   
   {loading ? (
        <Loader />
      ) : (
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                  <div>
                      <h4 className="page_heading">Update Bulk Upload</h4>
                      
                  </div>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={[]}
                        type={"Upload Format"} 
                        filename={"property_sourcing_book.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12 mb-4">
                    <div className=" d-flex">
                      <div>
                        <label className="form-label">Upload file : </label>
                        <input
                          type="file"
                          name="upload"
                          className="form-control mb-3"
                          id="upload"
                          ref={fileUploadRef}
                          onChange={readUploadFile}
                        />
                      </div>
                      <div className="searchbar" style={{ marginLeft: "auto" }}>
                        <input
                          type="text"
                          className="search"
                          onChange={handleFilter}
                          placeholder="..Search"
                        ></input>
                      </div>
                    </div>
                    <div className="mb-3">
                    <ExportButton
                        columns={columns}
                        data={excelData}
                        type={"Download"}
                        filename={"property_sourcing_book.csv"}
                      />
                      </div>
                      <div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      fixedHeader
                      persistTableHead={true}
                    />
                    </div>
                    <div className="text-end py-3 px-3"> 
                      <button type="submit" className="btn1" onClick={handleSubmit}>
                       Update
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}

      <Footerbar />
    </>
  );
}

export default UpdateBulkUpload;
