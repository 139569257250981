import React, { useEffect, useState } from "react";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropertyDocumentEdit from "./propertyDocumentEdit";
import ExportButton from "../../../Utils/ExportButton";
import { SearchData } from "../../../Utils/Search";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchPropertyType } from "../../../Redux/Actions/MasterPage/PropertyTypeAction";
import { DocumentvalidateFormData } from "./propertyDocumentsFormValidation";
import Toast from "../../../Utils/Toast";
import {
  addPropertyDocument,
  deletePropertyDocument,
  fetchPropertyDocument,
} from "../../../Redux/Actions/MasterPage/PropertyDocumentAction";
import { DeleteById } from "../../../Utils/DeleteById";
import customStyle from "../../../Utils/tableStyle";

function PropertyDocument() {
  const propertyTypeData = useSelector(
    (state) => state.PropertyType.PropertyTypeData
  );

  const PropertyDocumentData = useSelector(
    (state) => state.PropertyDocument.PropertyDocumentData
  );
  const dispatch = useDispatch(); 
  useEffect(() => {
    dispatch(fetchPropertyType());
    dispatch(fetchPropertyDocument());
  }, [dispatch]);

  // PropertyType dropdown
  
  const options = propertyTypeData.map((data) => ({
    value: data.id,
    label: data.property_type,
  }));

  // store a adding new data
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({ 
    document: "",
    status: "",
    type: "",  
  }); 

 


  const handleChangeSelect = selectedOption => {
    setSelectedOption(selectedOption); 
    setFormData({
      ...formData,
      type: selectedOption.value,
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }; 
  // add

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationResult = DocumentvalidateFormData(formData);
    if (validationResult.isValid) {
      Toast({ message: "Added successfully", type: "success" });
      setErrors({});
      setFormData({ document: "", status: "", type: "" });  
      setSelectedOption(null);  
      await dispatch(addPropertyDocument(formData)); 
    } else {
      setErrors(validationResult.errors);
    }
  };
  

  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdat,
      sortable: true,
    },
    {
      name: "Property Type",
      selector: (row) => row.property_type,
      sortable: true,
    },
    {
      name: "Property Document",
      selector: (row) => row.document,
      width: "200px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.prop_status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

 

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [ 
    "sno",
    "createdat",
    "property_type",
    "document",
    "prop_status", 
  ];

  // filter
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(
    PropertyDocumentData,
    filterText,
    searchColumns
  );
  /////////////////////////////////////

  const [editData, setEditData] = useState();
  const handleEdit = (row) => { 
    setEditData(row);
  };


  // delete 
  const handleDelete = (row) => {
    DeleteById(row.id, deletePropertyDocument, dispatch);
  };
  // editing modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Topbar />
      <PropertyDocumentEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
        dropdown={options}
      />
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="page_heading">Add Property Document</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-3 ">
                        <label className="form-label" htmlFor="inputState">
                          Propert Type
                        </label>
                        <Select
                          options={options}
                          value={selectedOption} 
                          onChange={handleChangeSelect}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? "#e7e7e7"
                                : "#e7e7e7",
                              fontSize: "13px",
                            }),
                            option: (baseStyles, state) => ({
                              ...baseStyles,
                              fontSize: "12px",
                              color: "black",
                            }),
                          }}
                        />
                         {errors.type && <div className="validation_msg">{errors.type}</div>}

                      </div>
                      <div className="col-md-12 mb-3 ">
                        <label htmlFor="lastName" className="form-label">
                          Propert Document
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="document"
                          value={formData.document}
                          onChange={handleChange}
                        />     
                         {errors.document && <div className="validation_msg">{errors.document}</div>}
                       </div> 
 
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="inputState">
                        Status
                      </label>
                      <select
                        id="inputState"
                        className="form-select"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option disable value=""> 
                          --Select Status-- 
                        </option>
                        <option value="Enable">Enable</option>
                        <option value="Disable">Disable</option>
                      </select>
                      {errors.status && <div className="validation_msg">{errors.status}</div>}

                    </div>
 
                    <div className="text-end py-3 px-3">
                      <a
                        href="javascript:void(0);"
                        className="btn1 text-dark me-1"
                        onClick={() => {
                          setFormData({ type: "", status: "", document: " " });
                          setErrors("");
                         setSelectedOption(null);   
                        }}
                      >
                        Clear
                      </a>
                      <button type="submit" className="btn1">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={PropertyDocumentData}
                        filename={"property_document.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      // selectableRows
                      persistTableHead={true}
                      fixedHeader
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactTooltip
        id="edit"
        place="bottom"
        content="Edit"
        style={{ fontSize: "10px" }}
      />
      <ReactTooltip
        id="delete"
        place="bottom"
        content="Delete"
        style={{ fontSize: "10px" }}
      />
      <Footerbar />
    </>
  );
}

export default PropertyDocument;
