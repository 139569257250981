import axios from "axios" 
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_BRANCH = "FETCH_BRANCH"
export const DELETE_BRANCH = "DELETE_BRANCH"
export const BRANCH_ID = "BRANCH_ID"

const fetchSuccess = (data) =>{
    return{
        type : FETCH_BRANCH,
        payload : data
    }
}

const deleteSuccess = (id) => { 
    return{
        type:DELETE_BRANCH,
        payload:id 
    }
}

const successBranchID = (id) =>{
    return{
        type:BRANCH_ID,
        payload:id
    }
}

export const fetchBranch = () => {
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/branch`) 
                dispatch(fetchSuccess(response.data))
          
        }catch(error){
            console.error("Error fetching branch" , error)
        }
    }
}

export const addBranch = (insertData) =>{
    return async (dispatch) => {
        try{
              await axios.post(`${API_BASE_URL}/branch`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchBranch())
        }catch(error){
             console.error('Error Adding Branch:',error)
        }
    }
}

export const updateBranch = (id,updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/branch/${id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchBranch())
        }catch(error){
             console.error('Error Updating Branch:',error)
        }
    }
}

export const deleteBranch = (id) => {
    return async (dispatch) => {
        try{
            await axios.delete(`${API_BASE_URL}/branch/${id}`); 
            dispatch(deleteSuccess(id));
        }catch(error){
            console.error('Error deleting branch:', error);
        }
    }
}

export const fetchBranchID = () =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/branch/3`);
            dispatch(successBranchID(response.data)) 
        }
        catch(error){
            console.error("Error fetching branch" , error)
        }
    }
}
 